import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './paste/Framework.css';

import Paste from './paste/Paste';
import { remoteService } from "./paste/RemoteService";

const req = remoteService();
const APP_NAME = "DN42 Paste UI";

class App extends Component {
  constructor(){
    super();

    this.state = {
      name: APP_NAME,
      api:  "Paste API (Version Snapshot)",
    }
  }

componentDidMount() {
  req('https://paste.dn42.us/app-info').get()
  .then((response) => {
    if (response.ok) return response.text().catch(()=>"");
    else return "Unknown API"; })
  .then((text) => { this.setState({api: text}); });
}

  render() {
    const {api, name} = this.state;
    return (
      <BrowserRouter basename={'/ui'}>
        <React.Fragment key={1}>
          <section className="container-responsive">
            <Switch>
              <Route path={`/`} component={Paste} />
            </Switch>
          </section>

          <footer>
            <span className="left text-muted">{name}</span>
            <span className="right text-muted">{api}</span>
          </footer>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

export default App;
